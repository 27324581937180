import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Carousel } from 'react-bootstrap'
import '../../components/css/tooplate-infinite-loop.css'

const ModalImg = ({ imgData }) => {

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  if (imgData === null) {
    return (
      <p>Sin imagenes disponibles</p>
    )
  }

  return (
    <>
      <button className='custom-btn' onClick={handleShow} >Ver imágenes</button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Carousel fade>
            {imgData.map((item, index) => (
              <Carousel.Item>
                <img className="d-block w-100" src={item.url} alt={`Imagen ${item.id}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalImg;
