import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import '../../components/css/tooplate-infinite-loop.css'
import UpdateService from '../../services/UpdateService';
import LoaderItem from '../../services/LoaderItem';
import config from '../../config';
import TooltipModal from '../../components/modals/ToolTipModal';
import { data } from 'jquery';
import { format } from 'highcharts';

const UpdateAuction = () => {
    const params = useParams()
    const domain = config.api_domain.domain;
    const id = params.id
    const urlGet = `${domain}/house/auction/auction_images/${id}`
    const urlUpdate = `${domain}/house/update_auction/${id}`

    const [datas, setDatas] = useState([])
    const [formData, setFormData] = useState({});
    const [showForm, setShowForm] = useState(true);
    const [showUpdatedText, setShowUpdatedText] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(urlGet);
                setDatas(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, urlGet, setDatas]);


    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                id: datas[0].id,
                minimal: datas[0].minimal,
                description: datas[0].description,
                auction_date: datas[0].auction_date,
                location: datas[0].location,
                mt2: datas[0].mt2,
                rooms: datas[0].rooms,
                bathrooms: datas[0].bathrooms,
                kitchens: datas[0].kitchens,
                process_detail: datas[0].process_detail,
                comercial_price: datas[0].comercial_price,
                type: datas[0].type,
                debt: datas[0].debt,
                item_class: datas[0].item_class,
                city: datas[0].city,
                sector: datas[0].sector,
                parkings: datas[0].parkings,
                util_room: datas[0].util_room,
                stratum: datas[0].stratum,
                view: datas[0].view,
                floor: datas[0].floor,
                state: datas[0].state,
            });
        }
    }, [datas]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeCheck = (e) => {
        const value = e.target.value; // Recoge directamente el valor del select
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService(urlUpdate, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };


    return (
        <div className='container'>
            {showForm ?
                <div className="form-floating mb-3">
                    <div className="row">
                        <h2>Generalidades</h2>
                        <div className="col-sm-3">
                            <label htmlFor="mt2">Mt2</label>
                            <input id="mt2" name="mt2" type="number" placeholder="Metros cuadrados del inmueble" value={formData.mt2} className="form-control" onChange={handleChange} required />
                            <br />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="rooms">Habitaciones</label>
                            <input id="rooms" name="rooms" type="number" placeholder="Número de habitaciones" value={formData.rooms} className="form-control" onChange={handleChange} />
                            <br />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="bathrooms">Baños</label>
                            <input id="bathrooms" name="bathrooms" type="number" placeholder="Número de baños" value={formData.bathrooms} className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="kitchens">Cocinas</label>
                            <input id="kitchens" name="kitchens" type="number" placeholder="Número de cocinas" value={formData.kitchens} className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="type">Tipo de inmueble <TooltipModal text={'Ejemplo: Lote, Casa, Apartamento...'} title={'Que se esta rematando?'} /></label>
                            <input id="type" name="type" type="text" value={formData.type} placeholder="Tipo" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="auction_date">Fecha de la audiencia de remate <TooltipModal text={'Ejemplo: 28/02/2024'} title={'Fecha del remate'} /></label>
                            <input id="auction_date" name="auction_date" type="date" value={formData.auction_date} placeholder="Fecha del remate" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="city">Ciudad <TooltipModal text={'Ciudad de ubicación del bien'} title={'Ciudad'} /></label>
                            <input id="city" name="city" value={formData.city} type="text" placeholder="Ciudad" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="location">Locación del remate <TooltipModal text={'Locación donde está ubicado el bien'} title={'Locación'} /></label>
                            <input id="location" name="location" value={formData.location} type="text" placeholder="Locación" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="parkings">Número de parkings</label>
                            <input id="parkings" name="parkings" value={formData.parkings} type="number" placeholder="Cantidad de parqueaderos" className="form-control" onChange={handleChange} />
                            <br />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="util_room">Número de cuartos útiles</label>
                            <input id="util_room" name="util_room" value={formData.util_room} type="number" placeholder="Número de cuartos útiles" className="form-control" onChange={handleChange} />
                            <br />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="stratum">Estrato</label>
                            <input id="stratum" name="stratum" value={formData.stratum} type="number" placeholder="Estrato" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-3">
                            <label htmlFor="view">Vista del inmueble</label>
                            <input id="view" name="view" type="text" value={formData.view} placeholder="Vista" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="form-group col-sm-4">
                            <label htmlFor="state" className="form-label">
                                Estado <TooltipModal text={'Estado del remate, si está activo o inactivo'} title={'Estado del remate'} />
                            </label>
                            <select
                                className="form-select"
                                id="state"
                                name="state"
                                value={formData.state} // Sincronizar con el estado actual
                                onChange={handleChangeCheck} // Manejar cambios
                            >
                                <option value="1">Activo</option>
                                <option value="0">Inactivo</option>
                            </select>
                            <br /><br />
                        </div>

                        <div className="col-sm-4">
                            <br />
                            <a href={`../images_auction/${formData.id}`}><button className='btn btn-secondary'>Imagenes del inmueble</button></a>
                            <br />
                        <br />
                        </div>


                        <div className="col-sm-12">
                            <label htmlFor="process_detail">Detalle del proceso <TooltipModal text={'Ejemplo: '} title={'Detalle del proceso de remate'} /></label>
                            <textarea rows={2} id="process_detail" name="process_detail" value={formData.process_detail} type="text" placeholder="Detalle del proceso" className="form-control" onChange={handleChange} />
                            <br />
                        </div>
                        <div className="col-sm-12">
                            <label htmlFor="description">Descripción<TooltipModal text={'Usado para el detalle'} title={'Detalle del remate'} /></label>
                            <textarea rows={4} id="description" name="description" type="textarea" placeholder="Descripción del inmueble" className="form-control" value={formData.description} onChange={handleChange} required />
                            <br />
                        </div>
                        <br />

                        <h2>Aspectos economicos</h2>
                        <br />
                        <div className="col-sm-4">
                            <label htmlFor="comercial_price">Precio comercial <TooltipModal text={'Valor comercial del bien'} title={'Avaluo comercial'} /></label>
                            <input id="comercial_price" value={formData.comercial_price} name="comercial_price" type="number" placeholder="Precio" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <div className="col-sm-4">
                            <label htmlFor="minimal">Postura mínima <TooltipModal text={'Valor en porcentaje de la postura minima que dictamina el proceso. Ej: 70%'} title={'Postura mínima'} /></label>
                            <input id="minimal" name="minimal" value={formData.minimal} type="text" placeholder="Título del inmueble" className="form-control" onChange={handleChange} required />
                            <br />
                        </div>

                        <div className="col-sm-4">
                            <label htmlFor="debt">Otras deudas del proceso <TooltipModal text={'Otras deudas del inmueble como por ejemplo el impuesto predial'} title={'Otras deudas del proceso'} /></label>
                            <input id="debt" name="debt" value={formData.debt} type="number" placeholder="Deudas del proceso" className="form-control" onChange={handleChange} />
                            <br />
                        </div>

                        <br />
                    </div>
                    <button onClick={handleSubmit} className="btn btn-primary">Enviar</button>
                    <br />
                </div>
                : (
                    <>
                        <p>Parámetros actualizados</p>
                        <button className='btn btn-primary' onClick={handleOpenForm}>Editar Parámetros</button>
                    </>
                )}
            <br />

        </div>
    )
}

export default UpdateAuction;