import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import LoaderItem from '../../services/LoaderItem';
import PostService from '../../services/PostService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../components/css/tooplate-infinite-loop.css';
import DeleteService from '../../services/DeleteItem';
import { useCookies } from 'react-cookie';
import config from '../../config';
const domain = config.api_domain.domain

const Process = () => {
    const params = useParams();
    const id = params.id;
    const url = `${domain}/house/process/${id}`;
    const [cleanDataDetail, setCleanDataDetail] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [nameProcess, setNameProcess] = useState('')
    const [urlProcess, setUrlProcess] = useState('');
    const [cookies] = useCookies(['token_auth_sesion']);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = cookies.token_auth_sesion;
                const item = await LoaderItem(url, token);
                setCleanDataDetail(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, url]);

    const handleSaveProcess = async () => {
        try {
            let data = {
                item_id: id,
                url: urlProcess,
                name: nameProcess
            }
            await PostService('house/insert_process', data);
            window.location.reload();
        } catch (error) {
            console.error('Error al guardar el proceso:', error);
        }
    };


    const handleDeleteProcess = async (id_item) => {
        try {
            let data = {}
            await DeleteService(`house/delete_process/${id_item}`, data);
            window.location.reload()
        } catch (error) {
            console.error('Error al eliminar el proceso:', error);
        }
    };

    return (
        <div className='container'>
            <h2 className="tm-text-primary mb-4">Links del proceso</h2>
            {showForm === false ? <div>
                <button className='btn btn-primary' onClick={() => setShowForm(!showForm)}>Agregar Link</button>
            </div> : <></>}
            {showForm && (
                <div className="container mt-4">
                    <p className="h5 mb-3">Crear nuevo link</p>

                    <div className="mb-3">
                        <label htmlFor="nameProcess" className="form-label">Nombre del proceso</label>
                        <input
                            type="text"
                            id="nameProcess"
                            className="form-control"
                            placeholder="Nombre del proceso"
                            value={nameProcess}
                            onChange={(e) => setNameProcess(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="urlProcess" className="form-label">URL del proceso</label>
                        <input
                            type="text"
                            id="urlProcess"
                            className="form-control"
                            placeholder="Ingrese la URL del proceso"
                            value={urlProcess}
                            onChange={(e) => setUrlProcess(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleSaveProcess}>Guardar link</button>
                </div>

            )}
            <br /><br />
            <table className='table'>
                <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Link</th>
                    <th scope="col-2" style={{ minWidth: "130px", maxWidth: "10%", width: "130px" }}>Opciones</th>
                </tr>
                </thead>
                <tbody>
                    {cleanDataDetail.map((item, index) => (
                        <tr className='' key={index}>
                            <td >{item.name}</td>
                            <td><a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a> </td>
                            <td ><a><FontAwesomeIcon color='red' icon={faTrash} className='m-2' onClick={() => handleDeleteProcess(item.id)} /></a> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Process;
