import { useEffect, useState } from 'react';
import '../../components/css/tooplate-infinite-loop.css'
import LoaderItem from "../../services/LoaderItem";
import config from '../../config';

const List = () => {
    const domain = config.api_domain.domain
    const url = `${domain}/house/list/houses`
    const [cleanDataDetail, setCleanDataDetail] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [url]);

    if (!cleanDataDetail || cleanDataDetail.length === 0 || cleanDataDetail === ![]) {
        // Cuando no hay elementos disponibles
        return <div>Sin elementos disponibles</div>;
      }
  
    return (
        <div className='container'>
            <h2 className='tm-text-primary mb-4'>Lista de inmuebles</h2>
            <br />
            <table>
                <thead>
                    <th>Código</th>
                    <th>Titulo</th>
                    <th>Ciudad</th>
                    <th>Sector</th>
                    <th>Estado</th>
                    <th>Opciones</th>
                </thead>
                <tbody>
                {cleanDataDetail.map((item, index) => (
                    <tr key={index}>              
                        <td>PV-{item.id}</td>
                        <td>{item.title}</td>
                        <td>{item.city}</td>
                        <td>{item.sector}</td>
                        <td style={item.state === "1" ? {color: "blue"} : {color: "red"}} ><strong>{item.state === "1" ? 'Activo' : 'Vendido'}</strong></td>
                        <td> <a href={`/admin/update/${item.id}`}>Editar</a></td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    )
}

export default List;