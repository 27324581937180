import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import LoaderItem from '../../services/LoaderItem';
import '../../components/css/tooplate-infinite-loop.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faMapMarker, faBath, faBed, faMap, faRestroom, faRuler, faNetworkWired, faStreetView, faFutbol, faWater, faStop, faSmile, faTree, faUtensils, faDumbbell, faSpoon } from '@fortawesome/free-solid-svg-icons';


import PriceFormatter from '../../components/priceFormatter/PriceFormater';
import Homebar from '../../components/homebar/Homebar';
import ModalImg from '../../components/modals/ModalImg';

import config from '../../config';
const domain = config.api_domain.domain

const ItemService = ({ FooterElement }) => {
    const params = useParams()
    const id = params.id
    const url = `${domain}/house/auction/auction_images/${id}`
    const [cleanDataDetail, setCleanDataDetail] = useState([])
    const [imagesUrl, setImagesUrl] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
                setImagesUrl((item[0].images))
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, url]);

    return (
        <div>
            <Homebar />
            <br /><br /><br />
            <div className='container'>
                {cleanDataDetail.length > 0 ? (
                    <>
                        <h2 className="tm-text-primary tm-section-title-alt mb-4"><strong>{cleanDataDetail[0].item_class}</strong> Remate de {cleanDataDetail[0].type}</h2>
                        <div>Fecha del proceso: <strong className='tm-text-primary'>{cleanDataDetail[0].auction_date}</strong></div>
                        <p>{cleanDataDetail[0].short_desc}</p>
                        <div><FontAwesomeIcon className='blue-item' icon={faMapMarker} /> <strong>{cleanDataDetail[0].city}</strong>, {cleanDataDetail[0].location}  </div>
                        <br />
                        <hr /><br />
                        <div className="item-features">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2 className="tm-text-primary tm-section-title-alt mb-4">Carácteristicas</h2>
                                    <div className="row">

                                        <div className='col-sm-3'><p> <FontAwesomeIcon className='blue-item' icon={faRuler} /> mt2: {cleanDataDetail[0].mt2}</p></div>

                                        {cleanDataDetail[0].rooms === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faBed} /> Habitaciones: {cleanDataDetail[0].rooms}</p></div>}

                                        {cleanDataDetail[0].bathrooms === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faBath} /> Baños: {cleanDataDetail[0].bathrooms}</p></div>}

                                        {cleanDataDetail[0].parkings === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faCar} /> Parqueaderos: {cleanDataDetail[0].parkings}</p></div>}

                                        {cleanDataDetail[0].util_room === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faRestroom} /> Cuarto útil: {cleanDataDetail[0].util_room}</p></div>}

                                        {cleanDataDetail[0].kitchens === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faSpoon} /> Cocinas: {cleanDataDetail[0].kitchens}</p></div>}

                                        <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faNetworkWired} /> Estrato: {cleanDataDetail[0].stratum}</p></div>

                                        {cleanDataDetail[0].view === 0 ? <></> :                                         <div className='col-sm-3'><p><FontAwesomeIcon className='blue-item' icon={faStreetView} /> Vista: {cleanDataDetail[0].view}</p></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <br />
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="sec-description">
                                    <h2 className="tm-text-primary tm-section-title-alt mb-4">Descripción del bien</h2>
                                    <div>{cleanDataDetail[0].description}</div>
                                </div>
                                <br />
                                <div className="sec-description">
                                    <h2 className="tm-text-primary tm-section-title-alt mb-4">Detalle del proceso</h2>
                                    <div>{cleanDataDetail[0].process_detail}</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <h2 className="tm-text-primary tm-section-title-alt mb-4">Precio</h2>
                                <div className="row">
                                    <div className='col-sm-12'><p>Avalúo comercial: <strong> $<PriceFormatter value={cleanDataDetail[0].comercial_price} /></strong></p> </div>

                                    <div className='col-sm-12'><p>Postura minima: <strong><PriceFormatter value={cleanDataDetail[0].minimal} />%</strong></p> </div>

                                    <div className="col-sm-12"> <p><strong className='final-price blue-item'>$<PriceFormatter value={cleanDataDetail[0].comercial_price * (cleanDataDetail[0].minimal / 100)} /></strong></p></div>

                                    <div className='col-sm-12'><p>Otros gastos aproximados: $<strong><PriceFormatter value={cleanDataDetail[0].debt}/></strong></p> </div>
                                </div>
                            </div>
                        </div>
                        <ModalImg imgData={imagesUrl} />
                        <br />
                        <br />
                        <strong><a target='blank__' href="https://api.whatsapp.com/send?phone=573007220272&text=Hola!, estoy interesado los servicios!">¡Solicita más información dando clic aquí!</a></strong>
                        <br /><br /><br />
                    </>
                ) : (
                    'Cargando...'
                )}
            </div>
            <FooterElement />
        </div>
    )
}

export default ItemService;
