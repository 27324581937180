import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import LoginService from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [cookies, setCookie] = useCookies(['token']);
    const [error, setError] = useState(''); // Estado para el mensaje de error
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Limpiar el mensaje de error antes de intentar iniciar sesión

        try {
            const token = await LoginService(username, password);

            if (token) {
                setCookie('token_auth_sesion', token, { 
                    path: '/', 
                    maxAge: 18000
                });
                
                navigate('/admin/control'); // Redirigir después del login exitoso
                window.location.reload();
            } else {
                setError('Usuario o contraseña incorrectos'); // Mostrar mensaje de error
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            setError('Error al intentar iniciar sesión. Intenta nuevamente.'); // Mensaje de error genérico
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className="card p-4 shadow" style={{ width: '350px' }}>
                <h3 className="text-center mb-4">Iniciar Sesión</h3>
                {error && ( // Mostrar alerta si hay un mensaje de error
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}
                <form onSubmit={handleLogin}>
                    <div className="form-group mb-3">
                        <label htmlFor="username">Nombre de usuario:</label>
                        <input
                            id="username"
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="password">Contraseña:</label>
                        <input
                            id="password"
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                        Iniciar Sesión
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
