import React from 'react'
import { useState } from 'react'
import Swal from "sweetalert2"
import PostService from '../../services/PostService'
import TooltipModal from '../../components/modals/ToolTipModal'

const NewAuction = () => {

    const [createdDone, setCreatedDone] = useState(false)

    const [minimal, setMinimal] = useState() //OK
    const [description, setDescription] = useState() //OK
    const [location, setLocation] = useState() //OK
    const [processDetail, setProcessDetail] = useState() //OK
    const [auctionDate, setAuctionDate] = useState() //OK
    const [type, setType] = useState() //OK
    const [comercialPrice, setComercialPrice] = useState() //OK
    const [mt2, setMt2] = useState() //OK
    const [rooms, setRooms] = useState() //OK
    const [bathrooms, setBathrooms] = useState() //OK
    const [parkings, setParkings] = useState() //OK
    const [utilRoom, setUtilRoom] = useState() //OK
    const [kitchens, setKitchens] = useState() //OK
    const [stratum, setStratum] = useState() //OK
    const [view, setView] = useState() //OK
    const [debt, setDebt] = useState() //OK
    const [city, setCity] = useState()  //Ok
    const [state, setState] = useState() //OK


    const handleMinimal = (e) => { setMinimal(e.target.value) }
    const handleDescription = (e) => { setDescription(e.target.value) }
    const handleLocation = (e) => { setLocation(e.target.value) }
    const handleProcessDetail = (e) => { setProcessDetail(e.target.value) }
    const handleAuctionDate = (e) => { setAuctionDate(e.target.value) }
    const handleType = (e) => { setType(e.target.value) }
    const handleComercialPrice = (e) => { setComercialPrice(e.target.value) }
    const handleMt2 = (e) => { setMt2(e.target.value) }
    const handleRooms = (e) => { setRooms(e.target.value) }
    const handleBathrooms = (e) => { setBathrooms(e.target.value) }
    const handleParkings = (e) => { setParkings(e.target.value) }
    const handleUtilRoom = (e) => { setUtilRoom(e.target.value) }
    const handleStratum = (e) => { setStratum(e.target.value) }
    const handleKitchens = (e) => { setKitchens(e.target.value) }

    const handleView = (e) => { setView(e.target.value) }
    const handleDebt = (e) => { setDebt(e.target.value) }
    const handleCity = (e) => { setCity(e.target.value) }

    const handleState = (e) => {
        const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        setState(value);
    };
    

    const handleSendInfo = async () => {
        try {
            const data = {
                "minimal" : minimal, //OK
                "description" : description, //OK
                "location" : location, //OK
                "process_detail" : processDetail, //OK
                "auction_date" : auctionDate, //OK
                "type" : type, //OK
                "comercial_price" : comercialPrice, //OK
                "mt2" : mt2, //OK
                "rooms" : rooms,  //OK
                "bathrooms" : bathrooms, //OK
                "parkings" : parkings, //OK
                "util_room" : utilRoom, //OK
                "stratum" : stratum, //OK
                "kitchens" : kitchens, //OK
                "view" : view, //OK 
                "debt" : debt, //OK
                "city" : city, //OK
                "state" : state //OK
            }

            await PostService('house/add_auction', data)
            setCreatedDone(true)
        } catch (error) {
            console.error(error)
        }
    }

    const handleNew = () => {
        setDescription('')
        setState(false)
        setCreatedDone(false)
    }

    const handleSendData = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se creará un nuevo inmueble. ¿Deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#0064C8',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendInfo()
                console.log('Inmueble creado con éxito')
            }
        })
    }

    return (
        <div className='container'>
            {createdDone === false ?
                <div className="form-floating mb-3">
                    <div className="row">
                        <h2>Generalidades</h2>


                        <div className="col-sm-3">
                        <label htmlFor="mt2">Mt2</label>
                            <input id="mt2" name="mt2" type="number" placeholder="Metros cuadrados del inmueble" className="form-control" onChange={handleMt2} required />
                        <br />
                        </div>
                        <div className="col-sm-3">
                        <label htmlFor="rooms">Habitaciones</label>
                            <input id="rooms" name="rooms" type="number" placeholder="Número de habitaciones" className="form-control" onChange={handleRooms} />
                        <br />
                        </div>
                        <div className="col-sm-3">
                        <label htmlFor="bathrooms">Baños</label>
                            <input id="bathrooms" name="bathrooms" type="number" placeholder="Número de baños" className="form-control" onChange={handleBathrooms} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="kitchens">Cocinas</label>
                            <input id="kitchens" name="kitchens" type="number" placeholder="Número de cocinas" className="form-control" onChange={handleKitchens} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="type">Tipo de inmueble <TooltipModal text={'Ejemplo: Lote, Casa, Apartamento...'} title={'Que se esta rematando?'}/></label>
                            <input id="type" name="type" type="text" placeholder="Tipo" className="form-control" onChange={handleType} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="auction_date">Fecha de la audiencia de remate <TooltipModal text={'Ejemplo: 28/02/2024'} title={'Fecha del remate'}/></label>
                            <input id="auction_date" name="auction_date" type="date" placeholder="Fecha del remate" className="form-control" onChange={handleAuctionDate} />
                        <br />
                        </div>


                        <div className="col-sm-3">
                        <label htmlFor="city">Ciudad <TooltipModal text={'Ciudad de ubicación del bien'} title={'Ciudad'}/></label>
                            <input id="city" name="city" type="text" placeholder="Ciudad" className="form-control" onChange={handleCity} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="location">Locación del remate <TooltipModal text={'Locación donde está ubicado el bien'} title={'Locación'}/></label>
                            <input id="location" name="location" type="text" placeholder="Locación" className="form-control" onChange={handleLocation} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="parkings">Número de parkings</label>
                            <input id="parkings" name="parkings" type="number" placeholder="Cantidad de parqueaderos" className="form-control" onChange={handleParkings} />
                        <br />
                        </div>
                        <div className="col-sm-3">
                        <label htmlFor="util_room">Número de cuartos útiles</label>
                            <input id="utilRoom" name="utilRoom" type="number" placeholder="Número de cuartos útiles" className="form-control" onChange={handleUtilRoom} />
                        <br />
                        </div>
                        <div className="col-sm-3">
                        <label htmlFor="stratum">Estrato</label>
                            <input id="stratum" name="stratum" type="number" placeholder="Estrato" className="form-control" onChange={handleStratum} />
                        <br />
                        </div>

                        <div className="col-sm-3">
                        <label htmlFor="view">Vista del inmueble</label>
                            <input id="view" name="view" type="text" placeholder="Vista" className="form-control" onChange={handleView} />
                        <br />
                        </div>

                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="state" onChange={handleState} />
                            <label className="form-check-label" htmlFor="state">
                                Estado <TooltipModal text={'Estado del remate, si esta activo o inactivo'} title={'Estado del remate'}/>
                            </label>
                            <br /><br />
                        <br />
                        </div>


                        <div className="col-sm-12">
                        <label htmlFor="process_detail">Detalle del proceso <TooltipModal text={'Ejemplo: '} title={'Detalle del proceso de remate'}/></label>
                            <textarea rows={2} id="process_detail" name="process_detail" type="text" placeholder="Detalle del proceso" className="form-control" onChange={handleProcessDetail} />
                        <br />
                        </div>
                        <div className="col-sm-12">
                        <label htmlFor="description">Descripción<TooltipModal text={'Usado para el detalle'} title={'Detalle del remate'}/></label>
                            <textarea rows={4} id="description" name="description" type="textarea" placeholder="Descripción del inmueble" className="form-control" onChange={handleDescription} required />
                        <br />
                        </div>
                        <br />



                        <h2>Aspectos economicos</h2>
                        <br />
                        <div className="col-sm-4">
                        <label htmlFor="comercial_price">Precio comercial <TooltipModal text={'Valor comercial del bien'} title={'Avaluo comercial'}/></label>
                            <input id="comercial_price" name="comercial_price" type="number" placeholder="Precio" className="form-control" onChange={handleComercialPrice} />
                        <br />
                        </div>

                        <div className="col-sm-4">
                        <label htmlFor="minimal">Postura mínima <TooltipModal text={'Valor en porcentaje de la postura minima que dictamina el proceso. Ej: 70%'} title={'Postura mínima'}/></label>
                            <input id="minimal" name="minimal" type="text" placeholder="Título del inmueble" className="form-control" onChange={handleMinimal} required />
                        <br />
                        </div>

                        <div className="col-sm-4">
                        <label htmlFor="debt">Otras deudas del proceso <TooltipModal text={'Otras deudas del inmueble como por ejemplo el impuesto predial'} title={'Otras deudas del proceso'}/></label>
                            <input id="debt" name="debt" type="number" placeholder="Deudas del proceso" className="form-control" onChange={handleDebt} />
                        <br />
                        </div>







                    <br />
                    </div>
                    <button onClick={handleSendData} className="btn btn-primary">Enviar</button>
                <br />
                </div>
                :
                <div>
                    <h3>Inmueble creado con éxito!</h3>
                    <br />
                    <button className="btn bg-blue" onClick={handleNew}>Crear otro</button>
                <br />
                </div>
            }
        <br />
        </div>
    )    
}

export default NewAuction
