import React from 'react'
import { useState } from 'react'
import Swal from "sweetalert2"
import PostService from '../../services/PostService'
import TooltipModal from '../../components/modals/ToolTipModal'

const New = () => {

    const [createdDone, setCreatedDone] = useState(false)

    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [mt2, setMt2] = useState()
    const [rooms, setRooms] = useState()
    const [bathrooms, setBathrooms] = useState()
    const [price, setPrice] = useState()
    const [type, setType] = useState()
    const [itemClass, setItemClass] = useState()
    const [city, setCity] = useState()
    const [sector, setSector] = useState()
    const [parking, setParking] = useState()
    const [utilRoom, setUtilRoom] = useState()
    const [discount, setDiscount] = useState()
    const [shortDesc, setShortDesc] = useState()
    const [predial, setPredial] = useState()
    const [administration, setAdministration] = useState()
    const [services, setServices] = useState()
    const [stratum, setStratum] = useState()
    const [view, setView] = useState()
    const [comfort, setComfort] = useState("0")
    const [pool, setPool] = useState("0")
    const [goal, setGoal] = useState("0")
    const [greenArea, setGreenArea] = useState("0")
    const [childrenArea, setChildrenArea] = useState("0")
    const [communalLiving, setCommunalLiving] = useState("0")
    const [gym, setGym] = useState("0")
    const [field, setField] = useState("0")
    const [maps, setMaps] = useState()
    const [floor, setFloor] = useState()
    const [year, setYear] = useState()
    const [statusWork, setStatusWork] = useState()
    const [state, setState] = useState()
    const [active, setActive] = useState(1)

    const handleTitle = (e) => { setTitle(e.target.value) }
    const handleDescription = (e) => { setDescription(e.target.value) }
    const handleMt2 = (e) => { setMt2(Number(e.target.value)) }
    const handleRooms = (e) => { setRooms(Number(e.target.value)) }
    const handleBathrooms = (e) => { setBathrooms(Number(e.target.value)) }
    const handlePrice = (e) => { setPrice(Number(e.target.value)) }
    const handleType = (e) => { setType(e.target.value) }
    const handleItemClass = (e) => { setItemClass(e.target.value) }
    const handleCity = (e) => { setCity(e.target.value) }
    const handleSector = (e) => { setSector(e.target.value) }
    const handleParking = (e) => { setParking(Number(e.target.value)) }
    const handleUtilRoom = (e) => { setUtilRoom(Number(e.target.value)) }
    const handleDiscount = (e) => { setDiscount(Number(e.target.value)) }
    const handleShortDesc = (e) => { setShortDesc(e.target.value) }
    const handlePredial = (e) => { setPredial(Number(e.target.value)) }
    const handleAdministration = (e) => { setAdministration(Number(e.target.value)) }
    const handleServices = (e) => { setServices(Number(e.target.value)) }
    const handleStratum = (e) => { setStratum(Number(e.target.value)) }
    const handleView = (e) => { setView(e.target.value) }

    const handleComfort = (e) => { 
        const value = e.target.checked === true ? "1" : "0"
        setComfort(value) }

    const handlePool = (e) => {         
        const value = e.target.checked === true ? "1" : "0"
        setPool(value) }

    const handleGoal = (e) => {         
        const value = e.target.checked === true ? "1" : "0"
        setGoal(value) }

    const handleGreenArea = (e) => {         
        const value = e.target.checked === true ? "1" : "0"
        setGreenArea(value) }

    const handleChildrenArea = (e) => { 
        const value = e.target.checked === true ? "1" : "0"
        setChildrenArea(value) }

    const handleCommunalLiving = (e) => { 
        const value = e.target.checked === true ? "1" : "0"
        setCommunalLiving(value) }

    const handleGym = (e) => { 
        const value = e.target.checked === true ? "1" : "0"
        setGym(value) }

    const handleField = (e) => { 
        const value = e.target.checked === true ? "1" : "0"
        setField(value)
     }

    const handleMaps = (e) => { setMaps(e.target.value) }
    const handleFloor = (e) => { setFloor(Number(e.target.value)) }
    const handleYear = (e) => { setYear(Number(e.target.value)) }
    const handleStatusWork = (e) => { setStatusWork(e.target.value) }

    const handleState = (e) => {
        const value = e.target.checked === true ? "1" : "0"
        setState(value)
    }

    const handleActive = (e) => {
        const value = e.target.checked === true ? 1 : 0
        setState(value)
    }

    const handleSendInfo = async () => {
        try {
            const data = {
                "title": title,
                "description": description,
                "mt2": mt2,
                "rooms": rooms,
                "bathrooms": bathrooms,
                "price": price,
                "type": type,
                "item_class": itemClass,
                "city": city,
                "sector": sector,
                "parking": parking,
                "util_room": utilRoom,
                "discount": discount,
                "short_desc": shortDesc,
                "predial": predial,
                "administration": administration,
                "services": services,
                "stratum": stratum,
                "view": view,
                "comfort": comfort,
                "pool": pool,
                "goal": goal,
                "green_area": greenArea,
                "children_area": childrenArea,
                "communal_living": communalLiving,
                "gym": gym,
                "field": field,
                "maps": maps,
                "floor": floor,
                "year": year,
                "status_work": statusWork,
                "state": state,
                "active": active
            }

            await PostService('house/add', data)
            //console.log(data)
            setCreatedDone(true)
        } catch (error) {
            console.error(error)
        }
    }

    const handleNew = () => {
        setTitle('')
        setDescription('')
        // setState(false)
        setCreatedDone(false)
    }

    const handleSendData = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se creará un nuevo inmueble. ¿Deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#0064C8',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendInfo()
                console.log('Inmueble creado con éxito')
            }
        })
    }

    return (
        <div className='container'>
            {createdDone === false ?
                <div className="form-floating mb-3">
                    <div className="row">
                        <h2>Generalidades</h2>
                        <div className="col-sm-6">
                        <label htmlFor="title">Titulo <TooltipModal text={'Titulo atractivo comercialmente Ej: Casa remodelada'} title={'Titulo'}/></label>
                            <input id="title" name="title" type="text" placeholder="Título del inmueble" className="form-control" onChange={handleTitle} required />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="mt2">Mt2</label>
                            <input id="mt2" name="mt2" type="number" placeholder="Metros cuadrados del inmueble" className="form-control" onChange={handleMt2} required />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="rooms">Habitaciones</label>
                            <input id="rooms" name="rooms" type="number" placeholder="Número de habitaciones" className="form-control" onChange={handleRooms} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="bathrooms">Baños</label>
                            <input id="bathrooms" name="bathrooms" type="number" placeholder="Número de baños" className="form-control" onChange={handleBathrooms} />
                        <br />
                        </div>

                        <div className="col-sm-6">
                        <label htmlFor="type">Tipo de inmueble <TooltipModal text={'Ejemplo: Lote, Casa, Apartamento...'} title={'Que se esta rematando?'}/></label>
                            <input id="type" name="type" type="text" placeholder="Tipo" className="form-control" onChange={handleType} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="item_class">Clase de inmueble <TooltipModal text={'Clase del bien'} title={'Ejemplo: Venta, Remate'}/></label>
                            <input id="itemClass" name="itemClass" type="text" placeholder="Clase de inmueble" className="form-control" onChange={handleItemClass} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="city">Ciudad <TooltipModal text={'Ciudad de ubicación del bien'} title={'Ciudad'}/></label>
                            <input id="city" name="city" type="text" placeholder="Ciudad" className="form-control" onChange={handleCity} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="sector">Sector <TooltipModal text={'Sector donde esta ubicado el bien'} title={'Sector'}/></label>
                            <input id="sector" name="sector" type="text" placeholder="Sector" className="form-control" onChange={handleSector} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="parking">Número de parkings</label>
                            <input id="parking" name="parking" type="number" placeholder="Cantidad de parqueaderos" className="form-control" onChange={handleParking} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="util_room">Número de cuartos útiles</label>
                            <input id="utilRoom" name="utilRoom" type="number" placeholder="Número de cuartos útiles" className="form-control" onChange={handleUtilRoom} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="short_desc">Descripción corta <TooltipModal text={'Texto para tarjeta'} title={'Descripción corta'}/></label>
                            <input id="shortDesc" name="shortDesc" type="text" placeholder="Descripción corta" className="form-control" onChange={handleShortDesc} />
                        <br />
                        </div>
                        <div className="col-sm-12">
                        <label htmlFor="description">Descripción larga <TooltipModal text={'Usado para el detalle'} title={'Detalle del remate'}/></label>
                            <textarea rows={4} id="description" name="description" type="textarea" placeholder="Descripción del inmueble" className="form-control" onChange={handleDescription} required />
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Aspectos economicos</h2>
                        <div className="col-sm-6">
                        <label htmlFor="price">Precio <TooltipModal text={'Valor comercial del bien'} title={'Avaluo comercial'}/></label>
                            <input id="price" name="price" type="number" placeholder="Precio" className="form-control" onChange={handlePrice} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="discount">Descuento <TooltipModal text={'Ejemplo: 70%'} title={'Postura minima del remate'}/></label>
                            <input id="discount" name="discount" type="number" placeholder="Descuento" className="form-control" onChange={handleDiscount} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="predial">Predial mensual</label>
                            <input id="predial" name="predial" type="number" placeholder="Predial" className="form-control" onChange={handlePredial} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="administration">Administración</label>
                            <input id="administration" name="administration" type="number" placeholder="Administración" className="form-control" onChange={handleAdministration} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="services">Servicios</label>
                            <input id="services" name="services" type="number" placeholder="Servicios públicos" className="form-control" onChange={handleServices} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="stratum">Estrato</label>
                            <input id="stratum" name="stratum" type="number" placeholder="Estrato" className="form-control" onChange={handleStratum} />
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Otros</h2>
                        <div className="col-sm-6">
                        <label htmlFor="view">Vista del inmueble</label>
                            <input id="view" name="view" type="text" placeholder="Vista" className="form-control" onChange={handleView} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="maps">Google Maps <TooltipModal text={'Usado para remates'} title={'Remate'}/></label>
                            <input id="maps" name="maps" type="text" placeholder="Mapas" className="form-control" onChange={handleMaps} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="floor">Piso</label>
                            <input id="floor" name="floor" type="number" placeholder="Piso" className="form-control" onChange={handleFloor} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="year">Año de construcción</label>
                            <input id="year" name="year" type="number" placeholder="Año de construcción" className="form-control" onChange={handleYear} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="status_work">Estado de la obra</label>
                            <input id="status_work" name="statusWork" type="text" placeholder="Estado de la obra" className="form-control" onChange={handleStatusWork} />
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Comodidades</h2>
                        <br />
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="comfort" onChange={handleComfort} />
                            <label className="form-check-label" htmlFor="comfort">
                                Comodidades
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="pool" onChange={handlePool} />
                            <label className="form-check-label" htmlFor="pool">
                                Piscina
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="goal" onChange={handleGoal} />
                            <label className="form-check-label" htmlFor="goal">
                                Portería
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="greenArea" onChange={handleGreenArea} />
                            <label className="form-check-label" htmlFor="greenArea">
                                Zona verde
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="childrenArea" onChange={handleChildrenArea} />
                            <label className="form-check-label" htmlFor="childrenArea">
                                Zona de niños
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="communalLiving" onChange={handleCommunalLiving} />
                            <label className="form-check-label" htmlFor="communalLiving">
                                Salón comunal
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="gym" onChange={handleGym} />
                            <label className="form-check-label" htmlFor="gym">
                                Gimnasio
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="field" onChange={handleField} />
                            <label className="form-check-label" htmlFor="field">
                                Cancha
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="state" onChange={handleState} />
                            <label className="form-check-label" htmlFor="state">
                                Estado
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input className="form-check-input" type="checkbox" value="" id="active" onChange={handleActive} />
                            <label className="form-check-label" htmlFor="active">
                                Activo
                            </label>
                            <br /><br />
                        <br />
                        </div>
                    <br />
                    </div>
                    <button onClick={handleSendData} className="btn">Enviar</button>
                <br />
                </div>
                :
                <div>
                    <h3>Inmueble creado con éxito!</h3>
                    <br />
                    <button className="btn bg-blue" onClick={handleNew}>Crear otro</button>
                <br />
                </div>
            }
        <br />
        </div>
    )    
}

export default New
