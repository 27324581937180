import { useEffect, useState } from 'react';
import '../../components/css/tooplate-infinite-loop.css'
import BarChart from '../../components/charts/BarChart';
import ColumnsChart from '../../components/charts/ColumnsChart';
import LineChart from '../../components/charts/LineChart';
import config from '../../config';
import LoaderItem from '../../services/LoaderItem';

const Control = () => {
    const domain = config.api_domain.domain
    const url = `${domain}/house/list/counter`
    // const [cleanDataDetail, setCleanDataDetail] = useState([])
    const [arrayItems, setArrayItems] = useState([]);
    const [error, setError] = useState(null);

    const currentDate = new Date();
    const currentMonth = currentDate.getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);

                const itemsCurrentYear = item.filter(item => {
                    const dateItem = new Date(item.date_time);
                    return dateItem.getFullYear() === currentMonth;
                });

                const itemsCounter = {};
                itemsCurrentYear.forEach(item => {
                    const itemName = item.item;
                    itemsCounter[itemName] = (itemsCounter[itemName] || 0) + 1;
                });

                const generalCount = Object.entries(itemsCounter).map(([item, counter]) => ({ item, counter }));

                setArrayItems(generalCount);
            } catch (error) {
                setError(error);
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [url]);

    if (error) {
        return <div className='container'><p>PANEL ADMINISTRATIVO</p></div>;
    }
    return (
        <div className='container'>
            <h2 className='tm-text-primary mb-4'>Pantalla de control</h2>
            <br /><br />
            <div className="row">
                <div className="col-sm-6">
                    <ColumnsChart
                        title={'Mas buscados'}
                        datas={arrayItems.map(item => item.counter)}
                        labels={arrayItems.map(item => item.item)}
                    />
                </div>

                <div className="col-sm-6">
                    <BarChart data={{
                        categories: ['Clics en inmuebles'],
                        series: [
                            {
                                name: 'Ventas',
                                data: [3, 4, 8, 2, 5, 4],
                            },
                            {
                                name: 'Clics',
                                data: [1, 2, 3, 4, 6, 2],
                            }
                        ],
                    }} />
                </div>

                <div className="col-sm-12">
                    <LineChart
                        data={[
                            {
                                name: "Clics",
                                data: [5, 4, 3, 2, 5, 7, 9, 5, 4],
                            },
                        ]}
                        labels={[5, 4, 3, 2, 5, 7, 9, 5, 4]}
                    />
                </div>
            </div>
            <br /><br />
            <br /><br />
        </div>
    )
}

export default Control;